<template>
  <div class="v-app-header">
    <v-container>
      <v-card class="pa-8" outlined>
        <v-row>
          <v-col>
            <h1>
              {{ title }}
            </h1>
            <v-divider class="mt-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5>Client:</h5>
            <h4 v-if="currentRouteName.includes('Prebid')">
              {{ this.$store.state.prebidPlan.Client }}
            </h4>
            <h4 v-else>{{ this.$store.state.plan.Client }}</h4>
          </v-col>
          <v-col>
            <h5>Project Name:</h5>
            <h4 v-if="currentRouteName.includes('Prebid')">
              {{ this.$store.state.prebidPlan.ProjectName }}
            </h4>
            <h4 v-else>{{ this.$store.state.plan.ProjectName }}</h4>
          </v-col>
          <v-col>
            <h5 v-if="currentRouteName.includes('Prebid')">Project Code:</h5>
            <h5 v-else>Job Code:</h5>
            <h4 v-if="currentRouteName.includes('Prebid')">
              {{ this.$store.state.prebidPlan.ProjectCode }}
            </h4>
            <h4 v-else>{{ this.$store.state.plan.JobCode }}</h4>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "App",
  props: {
    title: String,
  },
  components: {},

  data: () => ({}),

  methods: {},
  computed: {
    currentRouteName() {
      console.log(this.$route.name);
      console.log(this.$store.state);
      return this.$route.name;
    },
  },
};
</script>

<style src="../styles/headers.scss" lang="scss"></style>

<style src="../styles/main.scss" lang="scss"></style>
