<template>
  <v-container>
    <v-row>
      <v-col>
        <p>ProjectName:</p>
      </v-col>

      <v-col>
        {{ projectName }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Job Number:</p>
      </v-col>

      <v-col>
        {{ jobNumber }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>Client:</p>
      </v-col>

      <v-col>
        {{ client }}
      </v-col>
    </v-row>
    <!-- <v-btn @click="debug"> debug </v-btn> -->
  </v-container>
</template>

<script>
export default {
  name: "App",
  props: {},
  components: {},

  data: () => ({}),
  mounted() {},

  methods: {
    debug() {
      console.log(this.$store.state.DigitalPlan);
    },
  },

  computed: {
    client() {
      return this.$store.state.plan.Client;
    },

    projectName() {
      return this.$store.state.plan.ProjectName;
    },

    jobNumber() {
      return this.$store.state.plan.JobNumber;
    },
  },
  methods: {},
};
</script>
